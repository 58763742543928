<script lang="ts">
	import arrowRightLong from "../../../core/assets/icons/arrow-right-long.svg?raw";
	import Icon from "./Icon.svelte";
	import type { StepProps } from "../templates/HomepageProps";
	import { nbspify } from "../../../core/utils/nbspify";

	export let steps: StepProps[];
	export let headline = "Jak to funguje?";
</script>

<div>
	<h2 class="decorative-headline">{headline}</h2>
	<div class="mt-12 flex flex-col items-center gap-24 lg:flex-row lg:items-start lg:justify-between lg:gap-8 xl:gap-16">
		{#each steps as step, index}
			<div
				class="relative flex max-w-[20rem] flex-col items-center text-center lg:max-w-[initial] lg:basis-[calc((100%-(2*4rem))/3)]"
			>
				<Icon class="mb-6 h-20 w-20 lg:mb-8 lg:h-[5.5rem] lg:w-[5.5rem]" icon={step.icon} />
				<div class="flex flex-col items-center gap-4 text-center">
					<p class="text-secondary text-xs font-semibold uppercase lg:text-base">{index + 1}. krok</p>
					<p class="text-md font-semibold leading-[1.35] lg:text-lg">{nbspify(step.title)}</p>
					<p class="text-xs lg:text-base">{nbspify(step.description)}</p>
				</div>
				{#if index < 2}
					<Icon
						class="absolute bottom-[-4.5rem] left-0 right-0 mx-auto w-12 rotate-90 lg:bottom-[initial] lg:left-[initial] lg:right-[-5rem] lg:top-0 lg:mx-0 lg:w-[6.5rem] lg:rotate-0"
						icon={arrowRightLong}
					/>
				{/if}
			</div>
		{/each}
	</div>
</div>
